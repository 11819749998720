<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="会员昵称：">
        <el-input v-model="searchForm.nickname" size="small" placeholder="请输入会员昵称"></el-input>
      </el-form-item>
      <el-form-item label="会员身份：">
        <el-select v-model="searchForm.identity_type" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="个人会员" :value="0"></el-option>
          <el-option label="企业会员" :value="1"></el-option>
          <el-option label="个人会员商家" :value="2"></el-option>
          <el-option label="企业会员商家" :value="3"></el-option>
          <el-option label="供应商品商家" :value="4"></el-option>
          <el-option label="代销商家" :value="5"></el-option>
          <el-option label="产品合伙人" :value="6"></el-option>
          <el-option label="城市合伙人" :value="7"></el-option>
          <el-option label="市场合伙人" :value="8"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系人：">
        <el-input v-model="searchForm.name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input v-model="searchForm.mobile" size="small"></el-input>
      </el-form-item>
      <el-form-item label="所属区域：">
        <el-cascader v-model="searchForm.city_id" :props="{ value: 'id', label: 'name', children: '_child' }" clearable filterable :options="cityList" size="small"></el-cascader>
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker
          v-model="searchForm.create_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="nickname" label="用户信息" width="200" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.user_info.nickname" :avatar="row.user_info.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="会员身份" width="200" align="center">
        <template v-slot="{ row }">
          <span v-if="row.identity_type.length > 1">多会员组合</span>
          <span v-else>{{ row.identity_type[0] | identityName }}</span>
          <el-tooltip popper-class="tooltip" placement="top" v-if="row.identity_type.length > 1">
            <i class="el-icon-warning-outline" style="font-size: 16px"></i>
            <template slot="content">
              <div>会员身份</div>
              <div>
                <span v-for="item in row.identity_type" :key="item">{{ item | identityName }}、</span>
              </div>
            </template>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="联系人" width="200" align="center"></el-table-column>
      <el-table-column prop="mobile" label="联系电话" width="200" align="center"></el-table-column>
      <el-table-column prop="city_name" label="所属区域" width="200" align="center">
        <template v-slot="{ row }">{{ row.province }}{{ row.city }}{{ row.area }}</template>
      </el-table-column>
      <el-table-column prop="service_sales_amount" label="累计服务订单金额" width="150" align="center">
        <template v-slot="{ row }">￥{{ row.service_sales_amount }}</template>
      </el-table-column>
      <el-table-column prop="service_order_count" label="累计服务订单数" width="150" align="center"></el-table-column>
      <el-table-column prop="goods_sales_amount" label="累计商品订单金额" width="150" align="center">
        <template v-slot="{ row }">￥{{ row.goods_sales_amount }}</template>
      </el-table-column>
      <el-table-column prop="goods_order_count" label="累计商品订单数" width="150" align="center"></el-table-column>
      <el-table-column prop="create_time" label="注册时间" width="200" align="center">
        <template v-slot="{ row }">{{ row.create_time | getDateformat }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="200">
        <template v-slot="{ row }">
          <el-button @click="details(row.id)" type="text" size="small">详情</el-button>
          <!-- <el-button @click="retirement(row.id)" type="text" size="small">清退</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import userInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    userInfo,
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        nickname: '',
        name: '',
        mobile: '',
        type: '',
        is_shop: '',
        identity_type: '',
        city_id: '',
        create_time: [],
      },
      cityList: [],
      list: [],
      total_number: 0,
    };
  },
  filters: {
    getDateformat: getDateformat,
    identityName(type) {
      let name = '';
      switch (type) {
        case 0:
          name = '个人会员';
          break;
        case 1:
          name = '企业会员';
          break;
        case 2:
          name = '个人会员商家';
          break;
        case 3:
          name = '企业会员商家';
          break;
        case 4:
          name = '供应商品商家';
          break;
        case 5:
          name = '代销商家';
          break;
        case 6:
          name = '产品合伙人';
          break;
        case 7:
          name = '城市合伙人';
          break;
        case 8:
          name = '市场合伙人';
          break;
      }
      return name;
    },
  },
  created() {
    this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
      if (res.code == 0) {
        let list = res.result.list;
        for (let i in list) {
          for (let y in list[i]._child) delete list[i]._child[y]._child;
        }
        this.cityList = list;
      }
    });
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    searchClear() {
      this.searchForm = {
        page: 1,
        rows: 10,
        nickname: '',
        name: '',
        mobile: '',
        type: '',
        is_shop: '',
        identity_type: '',
        city_id: '',
        create_time: [],
      };
      this.getList();
    },
    details(id) {
      this.$router.push({
        path: '/member/info',
        query: {
          id: id
        },
      });
    },
    retirement(id) {
      this.$confirm('请确认是否清退该会员', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.gateway.member.retirement, {
            id: id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('已清退');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      for (let i in searchForm.create_time) searchForm.create_time[i] = searchForm.create_time[i] / 1000;
      if (searchForm.city_id) {
        searchForm.province_id = searchForm.city_id[0];
        searchForm.area_id = searchForm.city_id[2];
        searchForm.city_id = searchForm.city_id[1];
      }
      this.$axios.post(this.$api.gateway.member.list, searchForm).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) list[i].identity_type = list[i].identity_type.split(',').map(Number);
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>